import { Permission, useCan } from "../../services/auth";

type Props = {
  perform: Permission;
};
const Can: React.FC<Props> = ({ children, perform }) => {
  const { data } = useCan(perform);
  const node = typeof children === "function" ? children() : <> {children}</>;
  return data ? node : null;
};

export default Can;
