import { useAsync, useAsyncCallback } from "react-async-hook";
import { useQuery } from "react-query";
import createEndpointBase from "../../api/utils/endpoint-base";
import {
  createErrorHandlingOptions,
  useNotificationDispatch,
} from "../../context/notification-context";
import { useAccountContext } from "../context/account-context";
import { useEffect, useState } from "react";
import { OrderType } from "../components/order-type-selector";
import { useBoardContext } from "../context/board-context";

const endPointBase = createEndpointBase();

export type State = {
  state: number;
};

export type KindResponse = {
  kind: "algorithm1" | "algorithm2" | null;
};

export type Trade = {
  asset: string;
  id: number;
  createdAt: Date;
  price: number;
  direction: number;
  value: number;
  comment: string | null;
  commission: number;
  margin: number;
};

export type TradeMargin = {
  value: number;
  fifo: number;
  commission: number;
};

export const useTradeStateByAsset = (asset: string) => {
  const key = `/api/bcs/trading/params/${asset}`;
  const { data, isLoading, error, refetch } = useQuery<{
    warning?: boolean;
  } | null>(key, () => {
    return endPointBase.load(key, endPointBase.createGetOptions());
  });

  return { data, isLoading, error, refetch };
};

export const useTradeStart = () => {
  const asset = useAccountContext();
  return useAsyncCallback(
    async (
      f: number,
      q: number,
      orderType: OrderType
    ): Promise<State | null> => {
      return endPointBase.load(
        `/api/bcs/trading/start?f=${f}&q=${q}&asset=${asset}&order_type=${orderType}`,
        endPointBase.createGetOptions()
      );
    }
  );
};

export const useTradeStop = () => {
  const asset = useAccountContext();
  return useAsyncCallback(async (): Promise<State | null> => {
    return endPointBase.load(
      `/api/bcs/trading/stop?asset=${asset}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useTrades = (date: string, endDate: string) => {
  const asset = useAccountContext();
  const board = useBoardContext();
  const key = `/api/bcs/trading/data/${asset}`;

  return useQuery<Trade[]>([key, date, endDate, board], async () => {
    return endPointBase.load(
      `${key}/?date=${date}&end=${endDate}&board=${board}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useAllTrades = (date: string, endDate: string) => {
  const key = "/api/bcs/trading/data";
  const board = useBoardContext();
  return useQuery<Trade[]>([key, date, endDate, board], async () => {
    return endPointBase.load(
      `${key}?date=${date}&end=${endDate}&board=${board}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useTradeMargin = (date: string, endDate: string) => {
  const key = "/api/bcs/trading/data/margin";
  const board = useBoardContext();
  return useQuery<TradeMargin>([key, date, endDate, board], async () => {
    return endPointBase.load(
      `${key}?date=${date}&end=${endDate}&board=${board}`,
      endPointBase.createGetOptions()
    );
  });
};

export const useBuy = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();

  return useAsyncCallback(
    (amount: number, asset: string, price?: number) => {
      return endPointBase.load(
        `/api/bcs/trading/buy?asset=${asset}`,
        endPointBase.createPostOptions({
          amount,
          price,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useSell = (onSuccess: () => void) => {
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    (amount: number, asset: string, price?: number) => {
      return endPointBase.load(
        `/api/bcs/trading/sell?asset=${asset}`,
        endPointBase.createPostOptions({
          amount,
          price,
        })
      );
    },
    {
      onSuccess,
      ...createErrorHandlingOptions(notificationDispatch),
    }
  );
};

export const useTradeParams = () => {
  const asset = useAccountContext();
  return useAsync(
    (): Promise<{
      f: number;
      sd: number;
      q: number;
      warning?: boolean;
      orderType: OrderType;
    } | null> =>
      endPointBase.load(
        `/api/bcs/trading/params?asset=${asset}`,
        endPointBase.createGetOptions()
      ),
    [asset]
  );
};

export const useSetTradeParams = (onSuccess: () => void) => {
  const asset = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useAsyncCallback(
    (data: { f: number; sd: number; q: number; orderType: string }) =>
      endPointBase.load(
        `/api/bcs/trading/params/${asset}`,
        endPointBase.createPostOptions(data)
      ),
    { ...createErrorHandlingOptions(notificationDispatch), onSuccess }
  );
};

export const usePrice = (asset?: string) => {
  const query = `/api/bcs/trading/price`;
  const account = useAccountContext();
  const notificationDispatch = useNotificationDispatch();
  return useQuery<number>(
    [query, account, asset],
    () =>
      endPointBase.load(
        `${query}?asset=${asset || account}`,
        endPointBase.createGetOptions()
      ),
    createErrorHandlingOptions(notificationDispatch)
  );
};

export const usePriceStream = (forceAsset?: string) => {
  const [price, setPrice] = useState(0);
  const contextAsset = useAccountContext();

  const asset = forceAsset || contextAsset;

  useEffect(() => {
    const response = fetch(`/api/bcs/trading/price-stream/${asset}`)
      .then((response) => response.body)
      .then((body) => {
        const reader = body?.getReader();
        if (reader) {
          new ReadableStream({
            start(controller) {
              const pump = () => {
                return reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  if (value) {
                    const data = String.fromCharCode
                      .apply(null, Array.from(value))
                      .split("|");
                    const price = Number(data[data.length - 1]);
                    setPrice(price);
                  }
                  pump();
                });
              };
              return pump();
            },
          });
        }
        return reader;
      });

    return () => {
      response.then((r) => {
        r?.cancel();
      });
    };
  }, [asset]);

  return price;
};

export const useAssetInfo = () => {
  const account = useAccountContext();
  const board = useBoardContext();
  const query = `/api/bcs/trading/asset-info/${board}?asset=${account}`;
  const notificationDispatch = useNotificationDispatch();
  return useQuery<{
    lotSize: number;
    step: number;
    asset: string;
    buyCollateral?: number;
    sellCollateral?: number;
  }>(
    [query],
    () => endPointBase.load(query, endPointBase.createGetOptions()),
    createErrorHandlingOptions(notificationDispatch)
  );
};
