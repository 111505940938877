import Box from "@mui/material/Box";
import ChartCore, {
  ArgumentAxis,
  Legend,
  IVisualRangeProps,
  ZoomAndPan,
  Point,
  Series,
  CommonSeriesSettings,
  ICommonSeriesSettingsProps,
} from "devextreme-react/chart";
import { useInteractionContext } from "../context/interaction-context";
import { useState } from "react";
import { isToday } from "date-fns";
import { useApiLimitsData } from "../services/binance";
import DateRangeSelector from "../components/date-range-selector";

const ChartComponent: any = ChartCore;
const ArgumentAxisComponent: any = ArgumentAxis;
const LegendComponent: any = Legend;
const ZoomAndPanComponent: any = ZoomAndPan;
const PointComponent: any = Point;
const CommonSeriesSettingsComponent: React.FC<ICommonSeriesSettingsProps> =
  CommonSeriesSettings as any;
const SeriesComponent: any = Series;
const minVisualRangeLength = { minutes: 10 };

function LimitsApp() {
  const { from, to } = useInteractionContext();

  const response = useApiLimitsData(from, to);

  const endDate = new Date(to);

  const [visualRange, setVisualRange] = useState<IVisualRangeProps>({
    startValue: from,
    endValue: isToday(new Date(to))
      ? new Date().toString()
      : endDate.toString(),
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <DateRangeSelector />
      <ChartComponent
        height="100%"
        dataSource={response.data || []}
        onLegendClick={(e: any) => {
          e.target.isVisible() ? e.target.hide() : e.target.show();
        }}
      >
        <SeriesComponent
          valueField="sapiUsed1M"
          name="sapiUsed1M"
        ></SeriesComponent>

        <SeriesComponent valueField="mbxUsed" name="mbxUsed"></SeriesComponent>

        <SeriesComponent
          valueField="mbxUsed1M"
          name="mbxUsed1M"
        ></SeriesComponent>

        <CommonSeriesSettingsComponent
          argumentField="createdAt"
          valueField="amount"
          type={"line"}
          ignoreEmptyPoints={true}
        >
          <PointComponent visible={false} />
        </CommonSeriesSettingsComponent>

        <ZoomAndPanComponent argumentAxis="both" />
        <ArgumentAxisComponent
          min={from}
          minVisualRangeLength={minVisualRangeLength}
          visualRange={visualRange}
          onVisualRangeChange={setVisualRange}
          argumentType="datetime"
        />
        <LegendComponent
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
        />
      </ChartComponent>
    </Box>
  );
}

export default LimitsApp;
