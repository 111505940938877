import {
  createNumberColumnFormatter,
  formatDateColumn,
  formatNumber0,
  formatNumber2,
  formatNumber5,
  formatNumber8,
} from "./utils/format";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useData, usePriceStream } from "./services/trade-api";
import { isToday } from "date-fns/esm";
import OrderDialog from "./components/order-dialog";
import { useInteractionContext } from "./context/interaction-context";
import { Wallet } from "./model";
import RenderLimitDirectionCell from "./components/render-limit-derection-cell";
import { usePriceFormatter } from "./binance/hooks/use-price-formatter";

const columns = [
  { field: "id", headerName: "N", width: 90, type: "number" },
  {
    field: "createdAt",
    headerName: "Дата Время",
    width: 130,
    type: "dateTime",
    valueFormatter: formatDateColumn,
  },
  {
    field: "price",
    headerName: "Цена",
    type: "number",
    width: 130,
  },
  {
    field: "value",
    headerName: "Количество",
    type: "number",
    width: 130,
    valueFormatter: createNumberColumnFormatter(formatNumber5),
    renderCell: (params: any) => {
      if (params.row.commonValue !== null && !params.row.completed) {
        return `${params.formattedValue} / ${formatNumber5(
          params.row.commonValue
        )}`;
      }
      return params.formattedValue;
    },
  },
  {
    field: "direction",
    headerName: "Направление",
    type: "number",
    width: 150,
    valueFormatter: (params: any) => {
      if (params.value === 0) {
        return "ERROR";
      }
      if (!params.value) {
        return "";
      }
      return params.value === -1 ? "SELL" : "BUY";
    },
    renderCell: RenderLimitDirectionCell,
  },
  {
    field: "commission",
    headerName: "Комиссия",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber8),
    renderCell: (params: any) => {
      return `${params.formattedValue} ${params.row.commissionAsset}`;
    },
  },
  {
    field: "comment",
    headerName: "Комментарий",
    type: "string",
    width: 150,
  },
  {
    field: "fee",
    headerName: "Вознаграждение",
    type: "number",
    width: 150,
    valueFormatter: createNumberColumnFormatter(formatNumber2),
  },
];

type Props = {
  wallet: Wallet;
  q: number;
};

export const Trades = ({ wallet, q }: Props) => {
  const { from, to } = useInteractionContext();
  const dataResponse = useData(from, to);

  const data = dataResponse.data || [];

  const quantity = data.reduce(
    (sum, item) => sum + item.direction * item.value,
    0
  );
  const positionValue = data.reduce(
    (sum, item) => sum - item.price * item.value * item.direction,
    0
  );

  const price = data[data.length - 1]?.price || 0;
  const currentPrice = usePriceStream();
  const formatPrice = usePriceFormatter();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(x) => x.id}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          loading={dataResponse.isLoading}
        />
      </Box>
      <Paper sx={{ display: "flex", gap: 5, padding: 2 }}>
        <Typography variant="body1">
          Позиция: {formatNumber5(quantity)}
          {Math.abs(quantity) < 0.0000001
            ? ""
            : ` x ${formatNumber2(Math.abs(positionValue / quantity))}`}
        </Typography>
        <Typography variant="body1">
          Оценка:{" "}
          {formatNumber5(
            positionValue +
              (isToday(new Date(to)) ? currentPrice : price) * quantity
          )}
        </Typography>
        <Typography variant="body1">
          Комиссия:{" "}
          {formatNumber5(data.reduce((sum, d) => sum + d.commission, 0))}
        </Typography>
        <Typography variant="body1">
          Цена: {formatPrice(currentPrice)}
        </Typography>
        <Typography variant="body1">
          Вознаграждение:{" "}
          {formatNumber0(data.reduce((sum, item) => sum + (item.fee || 0), 0))}
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <OrderDialog
            q={q}
            onClose={() => {
              dataResponse.refetch();
            }}
            wallet={wallet}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Trades;
