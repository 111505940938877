import MenuItem from "@mui/material/MenuItem";

import {
  useInteractionContext,
  useInteractionDispatch,
} from "../context/interaction-context";
import { useAssetList, useTickers } from "../services/api";
import { useEffect, useMemo, useState } from "react";
import Modal from "./modal";
import AddTickerDialog from "./add-ticker-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Arrow from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography/Typography";
import TextField from "@mui/material/Input";
import Search from "@mui/icons-material/Search";
import TradeIndicator from "./trade-indicator";
import { useAccountContext } from "../context/account-context";

const TickerSelector = () => {
  const { ticker } = useInteractionContext();
  const dispatch = useInteractionDispatch();
  const tickerResponse = useTickers();
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const coinInfoResponse = useAssetList();

  const activeTickers = useMemo(() => {
    const coinInfo = coinInfoResponse.data || [];
    const isActiveTicker = (ticker: string) => {
      const [base, quote] = ticker.split("_");
      return (
        coinInfo.find((info) => info.coin === base) &&
        coinInfo.find((info) => info.coin === quote)
      );
    };
    return (tickerResponse.data || []).filter(isActiveTicker);
  }, [tickerResponse.data, coinInfoResponse.data]);

  const accountId = useAccountContext();

  useEffect(() => {
    if (!activeTickers.includes(ticker)) {
      const firstActiveTicker = activeTickers[0];
      if (firstActiveTicker) {
        dispatch({
          type: "SET_TICKER",
          payload: firstActiveTicker,
        });
      }
    }
  }, [accountId, activeTickers, dispatch, ticker]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setAddDialogIsOpen(false);
    tickerResponse.refetch();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 0.4,
        alignItems: "center",
      }}
    >
      <Box>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="small"
          sx={{
            margin: 0,
            padding: 0,
            textTransform: "none",
          }}
          endIcon={<Arrow />}
        >
          <Typography variant="body1">{ticker}</Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box sx={{ display: "none" }}>
            <TextField
              id="asset-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toUpperCase())}
              sx={{
                width: "100%",
                padding: "0 1rem",
              }}
              startAdornment={<Search />}
            />
          </Box>
          {tickerResponse.data
            ?.filter((ticker) => ticker.includes(searchTerm))
            .map((ticker) => {
              return (
                <MenuItem
                  disabled={!activeTickers.includes(ticker)}
                  key={ticker}
                  onClick={() => {
                    dispatch({
                      type: "SET_TICKER",
                      payload: ticker,
                    });
                    handleClose();
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 0.6,
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <TradeIndicator accountId={accountId} ticker={ticker} />
                    <span>{ticker}</span>
                  </Box>
                </MenuItem>
              );
            })}
          <MenuItem
            onClick={() => {
              setAddDialogIsOpen(true);
            }}
          >
            Добавить
          </MenuItem>
        </Menu>
      </Box>
      <Modal isOpen={addDialogIsOpen} onClose={handleDialogClose}>
        <AddTickerDialog onClose={handleDialogClose} />
      </Modal>
    </Box>
  );
};

export default TickerSelector;
