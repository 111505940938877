import ChartCore, {
  ArgumentAxis,
  Legend,
  LoadingIndicator,
  Series,
  IVisualRangeProps,
  ZoomAndPan,
  Tooltip,
} from "devextreme-react/chart";
import { useBalanceHistory } from "../services/api";
import { useState } from "react";
import Box from "@mui/material/Box";
import { formatNumber2 } from "../utils/format";

const minVisualRangeLength = { minutes: 10 };

type Props = {
  date: string;
  endDate: string;
};

const BalanceHistoryChart = ({ date, endDate }: Props) => {
  const [visualRange, setVisualRange] = useState<IVisualRangeProps>();
  const data = useBalanceHistory(date, endDate);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <ChartCore height="100%" dataSource={data.data}>
          <Series
            argumentField="createdAt"
            valueField="balance"
            type="line"
          ></Series>
          <ZoomAndPan argumentAxis="both" />
          <ArgumentAxis
            minVisualRangeLength={minVisualRangeLength}
            visualRange={visualRange}
            onVisualRangeChange={setVisualRange}
            argumentType="datetime"
          />
          <LoadingIndicator enabled={true} />
          <Legend visible={false} />
          <Tooltip enabled format={formatNumber2} />
        </ChartCore>
      </Box>
    </Box>
  );
};

export default BalanceHistoryChart;
